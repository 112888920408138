html,
body,
div#root,
div.App.container {
    height: 100%;
}

body {
    background: #3d3d3d;
    margin: 0;
}

div {
    box-sizing:border-box;
}

div.App.container {
    /*display: flex;*/
    /*flex-flow: column nowrap;*/
    /*justify-content: space-around;*/
    /*align-items: center;*/
    max-height: 100%;
}

div.sentence-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  height: 70vh;
  max-height: 70vh;
}

div.sentence {
    background: white;
    margin: 2vw;
    padding: 4vw;
    font-family: 'Red Hat Display', sans-serif;
    font-size: 5vw;
    text-align: center;

    /* flip animation */
    animation: flip-horizontal-bottom 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal both;

    /* rounded corners */
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px; /* future proofing */
    -khtml-border-radius: 10px; /* for old Konqueror browsers */
}

div.sentence p {
    padding: 0;
    margin: 0;
}

.dictaphone.container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 30vh;
    max-height: 30vh;
}

.circle-button {
    border-radius: 50%;
    background: white;
    height: 15vw;
    width: 15vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 3vw;
    margin-right: 3vw;
}

.circle-button i.fas {
    color: #3d3d3d;
    font-size: 6vw;
}

.circle-button.active i.fas {
    color: #e28236;
}